export const advancedSearchForm = {
  location:[null],
  aoi:[null],
  beginDate:[null],
  endDate:[null]
}

export const searchForm = {
  text:[null]
}
