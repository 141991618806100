import { Snackbars } from '@core';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

const snackbars: Snackbars = {
  success: {
    bgColor: 'bg-primary-blue',
    textColor: 'text-primary-white',
    icon: faCheckCircle,
  },
  info: {
    bgColor: 'bg-primary-black',
    textColor: 'text-primary-white',
    icon: faInfoCircle,
  },
  warning: {
    bgColor: 'bg-primary-black',
    textColor: 'text-primary-white',
    icon: faExclamationCircle,
  },
  danger: {
    bgColor: 'bg-primary-magenta',
    textColor: 'text-primary-white',
    icon: faExclamationTriangle,
  },
};

export { snackbars };
