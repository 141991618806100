<div class="flex 
    flex-row
    justify-between
    {{ bgColor }}
    rounded-lg 
    {{ isSelectable ? 'hover:cursor-pointer' : '' }}
    {{ isEditable ? 'py-2' : 'py-4' }}
    px-4"
    
    >
    <div class="flex px-4 gap-4 w-full leading-loose" (click)="toggleSelect()">
        <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
        <span>{{ value }}</span>
    </div>
    <div class="flex flex-row gap-1" *ngIf="isEditable">
        <app-icon-button [icon]="faEdit" (btnClick)="onEditClick()" [hover]="'hover:bg-gray-300 rounded-xl'"></app-icon-button>
        <app-icon-button
            *ngIf="isDeleteEnabled"
            [icon]="faTrash"
            (btnClick)="onDeleteClick()"
            [hover]="'hover:bg-gray-300 rounded-xl'"
        ></app-icon-button>
    </div>
</div>
