<div class="bg-primary-black grid grid-cols-12 overflow-x-hidden
    ">
    <header class="col-span-12">
        <app-navbar
            (login)="onLogin()"
            (logout)="onLogout()"
            [isAuthenticated]="isLoggedIn"
            [userProfile]="userProfile"
            [isServiceProvider]="hasServiceProviderRole"
            [isServiceManager]="hasServiceManagerRole"
            [documentationItem]="navbarDocumentationItem"
            [supportItem]="navbarSupportItem"
        ></app-navbar>
    </header>
    <main class="col-span-12 min-h-fit ornaments">
        <router-outlet></router-outlet>
        <app-snackbar></app-snackbar>
    </main>
    <app-footer 
        class="col-span-12"
        [menuItems]="footerMenuItems"
    ></app-footer>
</div>