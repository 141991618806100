export const environment = {
    production: false,
    staging: false,
    develop: true,
    identity: {
      url: window["env"]["iam_url"] || "https://iam.dev.desp.space",
      realm: window["env"]["iam_realm"] || "desp",
      clientId: window["env"]["iam_client_id"] || "service-registry-frontend",
      userProfileUrl: window["env"]["iam_user_profile_url"] || "https://iam.dev.desp.space/realms/desp/account",
      clientManagementUrl: window["env"]["iam_client_management_url"] || "https://iam.dev.desp.space/admin/desp/console/#/desp/clients"
    },
    api: window["env"]["api"] || "https://service-registry-api.dev.desp.space"
  };
