import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Service, ServiceMultiOption } from '@core';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseUrl = `${environment.api}/services`;
  baseUrlOptions = `${environment.api}/options`;

  constructor(private http: HttpClient) { }

  getAllPublishedServices(): Observable<Service[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<Service[]>(url, this.httpOptions);
  }

  searchPublishedServices(queryParamString: string): Observable<Service[]> {
    const url = `${this.baseUrl}?${queryParamString}`;
    return this.http.get<Service[]>(url, this.httpOptions);
  }

  getPublishedService(serviceID: number): Observable<Service> {
    const url = `${this.baseUrl}/${serviceID}`;
    return this.http.get<Service>(url, this.httpOptions);
  }

  getPublishedServiceFeedback(serviceID: number): Observable<Service> {
    const url = `${this.baseUrl}/${serviceID}/feedback`;
    return this.http.get<Service>(url, this.httpOptions);
  }

  getAccessPoliciesOptions(): Observable<ServiceMultiOption[]> {
    const url = `${this.baseUrlOptions}/accessPolicies`;
    return this.http.get<ServiceMultiOption[]>(url, this.httpOptions);
  }

  getInterfacesOptions(): Observable<ServiceMultiOption[]> {
    const url = `${this.baseUrlOptions}/interfaces`;
    return this.http.get<ServiceMultiOption[]>(url, this.httpOptions);
  }

  getRegistrationMethodsOptions(): Observable<ServiceMultiOption[]> {
    const url = `${this.baseUrlOptions}/registrationMethods`;
    return this.http.get<ServiceMultiOption[]>(url, this.httpOptions);
  }

  getResourceConsumptionsOptions(): Observable<ServiceMultiOption[]> {
    const url = `${this.baseUrlOptions}/resourceConsumptions`;
    return this.http.get<ServiceMultiOption[]>(url, this.httpOptions);
  }

  getServiceTypesOptions(): Observable<ServiceMultiOption[]> {
    const url = `${this.baseUrlOptions}/types`;
    return this.http.get<ServiceMultiOption[]>(url, this.httpOptions);
  }
}
