<div class="m-2 p-2 w-3/4" *ngIf="!isLoading">
  <div class="text-white font-bold text-2xl m-2 p-2 font-lato">User Feedback</div>
  <div *ngIf="userFeedbackList.length === 0" class="m-2 p-2 text-white font-lexend">
    There are no comments on the service yet.
  </div>
  <div *ngFor="let userFeedback of userFeedbackList" class="bg-white rounded-lg m-4 p-2">
      <div class="flex flex-col mb-2 pl-2 gap-2">
          <div class="flex flex-row justify-between">
              <div class="flex flex-row my-auto">
                  <div *ngFor="let array of evaluationRatingConvertToArray(5)" class="flex flex-row">
                      <svg
                        aria-hidden="true"
                        [ngClass]="
                          evaluationRatingConvertToArray(ratingToInt(userFeedback.rating))[array] == array
                            ? 'text-primary-magenta'
                            : 'text-gray-300'
                        "
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        ></path>
                      </svg>
                  </div>
              </div>
              <div class="flex flex-row">
                <div class="font-lexend m-auto pr-4">
                 {{userFeedback.username ?? 'Anonymous User'}} | {{userFeedback.createdAt}} 
                </div>
                <div *ngIf="isMyService" class="flex flex-row">
                  <app-icon-button
                    [icon]="faCheck"
                    [iconClass]="'text-green-700'"
                    background="bg-primary-white rounded-full"
                    (btnClick)="approveFeedback(userFeedback)"
                    [disabled]="userFeedback.approved"
                  ></app-icon-button>
                  <app-icon-button
                    [icon]="faXmark"
                    [iconClass]="'text-red-700'"
                    background="bg-primary-white rounded-full"
                    (btnClick)="disapproveFeedback(userFeedback)"
                    [disabled]="!userFeedback.approved"
                  ></app-icon-button>
                  <app-icon-button
                    [icon]="faTrash"
                    background="bg-primary-white rounded-full"
                    (btnClick)="discardFeedback(userFeedback.id)"
                  ></app-icon-button>
                </div>
              </div>
          </div>
          <div class="w-1/2 font-lexend">{{userFeedback.comment}}</div>
      </div>
  </div>
  <div *ngIf="isUserLoggedIn" [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()" class="m-1 p-1">
    <div class="text-white font-bold m-1 p-1 font-lexend">Give your Feedback:</div>
    <div>
      <div
        class="ml-2 inline-flex items-center text-sm font-medium text-center text-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-50">
        <div *ngFor="let array of evaluationRatingConvertToArray(5); index as idx">
          <svg
            aria-hidden="true"
            [ngClass]="
              evaluationRatingConvertToArray(value)[array] == array
                ? 'text-primary-magenta'
                : 'text-gray-300'
            "
            class="w-5 h-5 {{ getStarColor(idx) }}"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            (click)="onValueChange(array)"
            (mouseenter)="onStarMouseover(idx)"
            (mouseleave)="onStarMouseLeave(idx)"
          >
            <path
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
            ></path>
          </svg>
          <input type="hidden" formControlName="rating">
        </div>
      </div> 
      <div>
        <textarea class="w-3/4 rounded-lg m-2 p-2 font-lexend" type="text" placeholder="Write a Comment..." id="comment" formControlName="comment"></textarea>
      </div>
    </div>
    <div class="m-1 p-1">
      <app-button [label]="'Submit Feedback'" [isDisabled]="feedbackForm.valid ? false: true" (buttonClick)="submitFeedback()"></app-button>
    </div>
  </div>
</div>
<div class="m-2 p-2 w-3/4 text-white font-lexend" *ngIf="isLoading">
  Loading User Feedback ...
</div>
