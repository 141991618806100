import { Validators } from "@angular/forms";

export const newServiceConfig = {
    name: [null, Validators.required],
    description: [null, Validators.required],
    shortDescription: [null, Validators.required],
    version: [null, Validators.required],
    accessPolicies: [null, Validators.required],
    resourceConsumptions: [null, Validators.required],
    types: [null, Validators.required],
    interfaces: [null, Validators.required],
    registrationMethods: [null, Validators.required],
    destineUsageProfile: [null],
    serviceUsageProfile: [null],
    contentSourceManagement: [null],
    plannedEvolutions: [null],
    documentationURI: [null]
};
