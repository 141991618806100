<button
  class="{{
    isWhite
      ? 'bg-primary-white text-secondary-black'
      : isDangerous ? 'bg-primary-magenta text-secondary-white'
      : isSecondary ? 'bg-secondary-blue text-primary-white'
      : 'bg-gradient-to-r from-primaryGradient-left to-primaryGradient-right text-primary-white'
  }}
  {{
    isDisabled
      ? 'opacity-50 cursor-not-allowed'
      : 'ease-in-out motion-safe:hover:scale-105 motion-safe:active:scale-100 hover:shadow-xl'
  }}
  {{ isFull ? 'w-full justify-center' : '' }}
   flex px-4 py-2 rounded-3xl {{uppercase ? 'uppercase' : ''}} transform duration-300 gap-4 font-lexend
    "
  [disabled]="isDisabled"
  [type]="type"
  (click)="onClick()"
>
  <fa-icon *ngIf="leftIcon" [icon]="leftIcon" [ngClass]="iconClass"></fa-icon>
  <span *ngIf="!noLabel">{{ label }}</span>
  <fa-icon *ngIf="rightIcon" [icon]="rightIcon" [ngClass]="iconClass"></fa-icon>
</button>
