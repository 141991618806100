import { Component, OnInit } from '@angular/core';
import { MenuItem, WebPortalService } from '@core';
import { KeycloakService } from 'keycloak-angular';
import { take } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  isLoggedIn: boolean;
  userProfile: any;
  userRoles: string[];

  footerMenuItems: MenuItem[] = [];
  footerItemsToExclude = ['support', 'documentation'];
  navbarDocumentationItem: MenuItem;
  navbarSupportItem: MenuItem;

  constructor(
    private keycloakService: KeycloakService,
    private webPortalService: WebPortalService
    ) {}

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(value => {
      this.isLoggedIn = value;
      if (this.isLoggedIn) {
        this.keycloakService.getKeycloakInstance().loadUserInfo().then(profile => {
          this.userProfile = profile;
          this.userRoles = this.keycloakService.getUserRoles();
        });
      }
    });

    this.webPortalService.getAllLabels()
    .pipe(take(1)).subscribe({
      next: (labels) => {
      labels.forEach(label => {
        if (!this.footerItemsToExclude.includes(label.slug)) {
          this.footerMenuItems.push({
            label: label.name,
            url: label.url
          });
          }
        });
      },
      error: () => this.footerMenuItems = [
        {label: 'DESP Certificate of Confirmity', url: ''},
        {label: 'Privacy Policy', url: ''},
        {label: 'Terms & Conditions', url: ''}
      ]
    });

    this.webPortalService.getLabelBySlug('documentation')
    .pipe(take(1)).subscribe({
      next: (label) => {
        this.navbarDocumentationItem = {
          label: label.name,
          url: label.url
        }
      },
      error: () => this.navbarDocumentationItem = {
        label: 'Documentation',
        url: 'https://www.esa.int/Applications/Observing_the_Earth/Destination_Earth'
        }
    });

    this.webPortalService.getLabelBySlug('support')
    .pipe(take(1)).subscribe({
      next: (label) => {
      this.navbarSupportItem = {
        label: label.name,
        url: label.url
      }
      },
      error: () => this.navbarSupportItem = {
        label: 'Support',
        url: 'https://www.esa.int/Applications/Observing_the_Earth/Destination_Earth'
      }
    });
  }

  onLogin() {
    this.keycloakService.login().then(() => {
      this.isLoggedIn = true;
      this.keycloakService.getKeycloakInstance().loadUserInfo().then(profile => {
        this.userProfile = profile;
        this.userRoles = this.keycloakService.getUserRoles();
      });
    });
  }

  onLogout() {
    this.keycloakService.logout().then(() => {
      this.isLoggedIn = false;
      this.userProfile = undefined;
    });
  }


  get hasServiceProviderRole() {
    return this.userRoles && this.userRoles.length > 0
      && this.userRoles.includes('desp-user');
  }

  get hasServiceManagerRole() {
    return this.userRoles && this.userRoles.length > 0 
      && this.userRoles.includes('operator');
  }
}
