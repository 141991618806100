import { Component, Input, Output } from '@angular/core';
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent {

  @Input() commentContent: string;
  @Input() createdDate: string;
  @Input() user: string;

  faUser = faUser;
  faCalendar = faCalendar;

  constructor() {}
}
