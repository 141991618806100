import { Option } from "@core";

const revisionStatus: Option[] = [
    {
        label: 'Pending',
        value: 'pending'
    },
    {
        label: 'Approved',
        value: 'approved'
    },
    {
        label: 'Rejected',
        value: 'rejected'
    }
];

export { revisionStatus }