import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit {

  hasThumbnail: boolean;

  @Output() clickEvent = new EventEmitter<string | number>();
  @Input() serviceName: string;
  @Input() serviceDescription: string;
  @Input() itemIdentifier: string | number;
  @Input() revisionCode: number;
  @Input() revisionInfoText: string;
  @Input()
  set photoUrl(value: string) {
    this.photo = value;
    if (!this.photo) {
      this.hasThumbnail = false;
      this.photo = 'https://res.cloudinary.com/deimospt/image/upload/v1659628047/backgrounds/services_pvcsuf.jpg';
    } else {
      this.hasThumbnail = true;
    }
  }

  get photoUrl() {
    return this.photo;
  }

  get thumbnail() {
    if (this.hasThumbnail) {
      return 'url(data:image/png;base64,' + this.photoUrl + ')'
    } else {
      return 'url(' + this.photoUrl + ')'
    }
  }

  photo: string;

  constructor() {}



  ngOnInit(): void {

  }

  onClickEvent() {
    this.clickEvent.emit(this.itemIdentifier);
  }
}
