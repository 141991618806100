import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Attachment, NewService, NewTransaction, Service, ServiceReview, Thumbnail, Transaction, ServiceRevision, ServiceRevisionIndex } from '@core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyServicesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseUrl = `${environment.api}/my-services`;
  attachmentBaseUrl = `${environment.api}/attachments`;
  thumbnailBaseUrl = `${environment.api}/thumbnails`;
  transactionBaseUrl = `${environment.api}/transactions`;

  constructor(private http: HttpClient) { }

  getAllUserServices(): Observable<Service[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<Service[]>(url, this.httpOptions);
  }

  searchUserServices(queryParamString: string): Observable<Service[]> {
    const url = `${this.baseUrl}?${queryParamString}`;
    return this.http.get<Service[]>(url, this.httpOptions);
  }

  getUserService(serviceUUID: string): Observable<Service> {
    const url = `${this.baseUrl}/${serviceUUID}`;
    return this.http.get<Service>(url, this.httpOptions);
  }

  registerNewService(serviceData: NewService): Observable<Service> {
    const url = this.baseUrl;
    return this.http.post<Service>(url, serviceData, this.httpOptions);
  }

  createServiceTransactions(serviceUUID: string, serviceRevisionID: number, transactionData: NewTransaction): Observable<NewTransaction> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/${serviceRevisionID}/transactions`;
    return this.http.post<NewTransaction>(url, transactionData, this.httpOptions);
  }

  createServiceAttachment(serviceUUID: string, serviceRevisionID: number, attachmentData: Attachment): Observable<Attachment> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/${serviceRevisionID}/attachments`;
    return this.http.post<Attachment>(url, attachmentData, this.httpOptions);
  }

  createServiceThumbnail(serviceUUID: string, serviceRevisionID: number, thumbnailData: Thumbnail): Observable<Thumbnail> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/${serviceRevisionID}/thumbnails`;
    return this.http.post<Thumbnail>(url, thumbnailData, this.httpOptions);
  }

  updateService(serviceUUID: string, serviceRevisionID: number, serviceData: NewService): Observable<ServiceRevision> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/${serviceRevisionID}`;
    return this.http.put<ServiceRevision>(url, serviceData, this.httpOptions);
  }

  deleteServiceThumbnail(thumbnailID: number): Observable<any> {
    const url = `${this.thumbnailBaseUrl}/${thumbnailID}`;
    return this.http.delete(url, this.httpOptions);
  }

  deleteServiceAttachment(attachmentID: number): Observable<any> {
    const url = `${this.attachmentBaseUrl}/${attachmentID}`;
    return this.http.delete(url, this.httpOptions);
  }

  deleteServiceTransaction(transactionID: number): Observable<any> {
    const url = `${this.transactionBaseUrl}/${transactionID}`;
    return this.http.delete(url, this.httpOptions);
  }

  changeServicePublishState(serviceUUID: string, revisionID: number, state: boolean): Observable<Service> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/${revisionID}/publish`;
    return this.http.put<Service>(url, {'approve': state}, this.httpOptions);
  }

  getServiceReviews(serviceID: number): Observable<ServiceReview[]> {
    const url = `${this.baseUrl}/${serviceID}/reviews`;
    return this.http.get<ServiceReview[]>(url, this.httpOptions);
  }

  getServiceRevisions(serviceUUID: string): Observable<ServiceRevision[]> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions`;
    return this.http.get<ServiceRevision[]>(url, this.httpOptions);
  }

  getServiceRevisionByID(serviceUUID: string, revisionID: number): Observable<ServiceRevision> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions?id=${revisionID}`;
    return this.http.get<ServiceRevision>(url, this.httpOptions);
  }

  getServiceRevisionByCode(serviceUUID: string, code: number): Observable<ServiceRevision> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions?code=${code}`;
    return this.http.get<ServiceRevision>(url, this.httpOptions);
  }

  getServiceRevisionIndex(serviceUUID: string): Observable<ServiceRevisionIndex[]> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/index`;
    return this.http.get<ServiceRevisionIndex[]>(url, this.httpOptions);
  }
}
