<div class="flex items-center mb-2 p-1 rounded-md hover:shadow-sm hover:cursor-pointer duration-300 hover:bg-secondary-100">
  <input
    [id]="identifier"
    [name]="value"
    [value]="value"
    type="checkbox"
    [checked]="isChecked"
    (change)="onClick()"
    class="w-4 h-4 text-primary-white bg-primary-black rounded focus:ring-primary-white dark:focus:ring-primary-white focus:ring-2"
  />
  <label [for]="identifier" class="ml-2 text-sm font-lexend font-medium text-primary-white">{{
    label
  }}</label>
  <span *ngIf="required" class="text-red-500">*</span>
</div>
