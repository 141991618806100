import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@core';
import { userFeedbackConfig } from '@core/configs/forms/userFeedback.config';
import { UserFeedback } from '@core/models/user-feedback';
import { UserFeedbackService } from '@core/services/user-feedback/user-feedback.service';
import { faCheck, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';
import { take } from 'rxjs';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent implements OnInit{

  @Input() serviceUuid: string;
  @Input() isUserLoggedIn = false;
  @Input('value') _value: number = 0;
  @Input() isMyService = false;

  userFeedbackList: UserFeedback[];
  rating: number;
  comment: string;
  
  isStarColored: Map<number, boolean> = new Map();
  
  feedbackForm: UntypedFormGroup;
  isLoading: boolean;

  faCheck = faCheck;
  faXmark = faXmark;
  faTrash = faTrash;

  constructor(
    private userFeedbackService: UserFeedbackService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService
  ){
    this.feedbackForm = formBuilder.group(userFeedbackConfig);
    for (let i = 0; i < 5; i++) {
      this.isStarColored.set(i, false);
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    if(this.isMyService){
      this.userFeedbackService.getAllUserFeedbackByServiceUuid(this.serviceUuid).pipe(take(1)).subscribe({
        next: (data) => {
          this.userFeedbackList = data;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      })
    }else {
      this.userFeedbackService.getUserFeedbackByServiceUuid(this.serviceUuid).pipe(take(1)).subscribe({
        next: (data) => {
          this.userFeedbackList = data;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      })
    }
  }

  ratingToInt(rating: number){
    return Math.round(rating);
  }

  onChange = (value: number) => {};
  onTouched = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onTouched();
  }

  writeValue(value: number): void {
   this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  evaluationRatingConvertToArray(lenght: number): any[] {
    return Array.from(Array(lenght).keys());
  }

  onValueChange(value: number) {
    const rating = value + 1;
    this.value = rating;
    this.onChange(rating);
    this.feedbackForm.controls['rating'].setValue(rating);
  }

  submitFeedback(){
    this.userFeedbackService.postUserFeedbackByServiceUuid(this.serviceUuid, this.feedbackForm.value)
    .pipe(take(1)).subscribe({
      next: (response)=>{
        this.snackbarService.success("Success", "Your review has been submited").during(3000).show();
        this.onValueChange(-1);
        this.feedbackForm.reset();
      },
      error: (error)=>{
        this.snackbarService.danger("Error" , "An error ocurred").during(3000).show();
      }
    })
  }

  approveFeedback(userFeedback: UserFeedback){
    this.userFeedbackService.approveUserFeedbackById(userFeedback.id, true)
    .pipe(take(1)).subscribe({
      next: (response)=>{
        userFeedback.approved = true;
        this.snackbarService.success("Success", "The review has been approved").during(3000).show();
      },
      error: (error)=>{
        this.snackbarService.danger("Error" , error.error.message).during(3000).show();
      }
    })
  }

  disapproveFeedback(userFeedback: UserFeedback){
    this.userFeedbackService.approveUserFeedbackById(userFeedback.id, false)
    .pipe(take(1)).subscribe({
      next: (response)=>{
        userFeedback.approved = false;
        this.snackbarService.success("Success", "The review has been disapproved").during(3000).show();
      },
      error: (error)=>{
        console.log(error)
        this.snackbarService.danger("Error" , error.error.message).during(3000).show();
      }
    })
  }

  discardFeedback(userFeedbackId: number){
    this.userFeedbackService.deleteUserFeedbackById(userFeedbackId)
    .pipe(take(1)).subscribe({
      next: (response)=>{
        this.snackbarService.success("Success", "The review has been discarded").during(3000).show();
        this.userFeedbackService.getAllUserFeedbackByServiceUuid(this.serviceUuid).pipe(take(1)).subscribe({
          next: (data) => {
            this.userFeedbackList = data;
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          }
        })
      },
      error: (error)=>{
        this.snackbarService.danger("Error" , error.error.message).during(3000).show();
      }
    })
  }

  onStarMouseover(index: number) {
    for (let i = 0; i < 5; i++) {
      if (i <= index) {
        this.isStarColored.set(i, true);
      } else {
        this.isStarColored.set(i, false);
      }
    }
  }

  onStarMouseLeave(index: number) {
    for (let i = 0; i < 5; i++) {
      this.isStarColored.set(i, false);
    }
  }

  getStarColor(index: number): string {
    if (this.isStarColored.get(index)) {
      return 'text-primary-magenta';
    } else {
      return '';
    }
  }
}
