<nav
  class="
    flex
    justify-between
    font-lato
    text-center
    py-4
    px-6
    bg-primary-black
    text-primary-white
  "
>
  <a href="/">
    <img src="assets/img/logo.png" class="h-15 w-20">
  </a>
  <div class="flex gap-8 text-base font-lato font-bold justify-between my-auto">
    <div [routerLink]="['services']" 
      routerLinkActive="text-primary-blue" 
      class="hover:cursor-pointer">Services</div>
    <div *ngIf="isServiceProvider || isServiceManager"
      [routerLink]="['my-services']"
      routerLinkActive="text-primary-blue"
      [routerLinkActiveOptions]="{exact: false}"
      class="hover:cursor-pointer">My Services</div>
    <div *ngIf="isServiceManager"
      [routerLink]="['service-desk']"
      routerLinkActive="text-primary-blue"
      [routerLinkActiveOptions]="{exact: false}"
      class="hover:cursor-pointer">Service Desk</div>
    <div *ngIf="documentationItem"
      class="hover:cursor-pointer">
        <a [href]="documentationItem.url" target="_blank">{{documentationItem.label}}</a>
    </div>
    <div *ngIf="supportItem"
      class="hover:cursor-pointer">
      <a [href]="supportItem.url" target="_blank">{{supportItem.label}}</a>
    </div>
  </div>

  <div class="flex gap-4 justify-end font-lexend my-auto pr-4">

      <div *ngIf="this.isAuthenticated; else signInButton" class="my-auto">
          <!-- <button
            class="
              bg-gradient-to-r
              from-primaryGradient-left 
              to-primaryGradient-right
              rounded-3xl
              h-9
              w-full
              px-4
              transform
              duration-300
              ease-in-out
              motion-safe:hover:scale-105 motion-safe:active:scale-100
            "
            *ngIf="getUserData() as user"
            (click)="isShowingProfileCard = !isShowingProfileCard"
          >
            <h5 class="m-0 text-gray-50 font-bold text-1xl font-lexend">
              {{ user.preferred_username | uppercase }}
            </h5>
          </button> -->
          <app-button
            *ngIf="getUserData() as user"
            [label]="(user.name ? user.name.split(' ')[0] : user.preferred_username.split(' ')[0])"
            [leftIcon]="faUser"
            (buttonClick)="isShowingProfileCard = !isShowingProfileCard"
          ></app-button>
      </div>
      <ng-template #signInButton>
        <app-button
          (click)="onLogin()"
          [label]="'Sign In'"
          [leftIcon]="signIn"
        ></app-button>
      </ng-template>
  </div>
</nav>


<div
  [@slideInOut]
  *ngIf="isShowingProfileCard"
  (outsideClick)="isShowingProfileCard = !isShowingProfileCard"
>
  <div
    *ngIf="getUserData() as user"
    class="
      z-50
      flex flex-shrink-0
      absolute
      my-2 mx-4
      right-0
      bg-primary-white
      rounded-2xl
      shadow-md
      w-auto
    "
  >
    <div
      class="
        flex
        justify-center
        bg-gradient-to-r from-primaryGradient-left to-primaryGradient-right
        mr-4
        px-4
        w-20
        rounded-l-2xl
        items-center
      "
    >
      <h1 class="m-0 text-primary-white text-3xl font-bold">
        {{ getInitials(user.name ?? user.preferred_username) }}
      </h1>
    </div>
    <div class="flex flex-1 justify-between items-start font-lexend">
      <div class="m-2">
        <div class="flex flex-col gap-2">
          <div>
            <h5>{{ user.name }}</h5>
            <span>{{ user.email }}</span>
          </div>
          <app-button
            [isWhite]="true"
            (click)="openUserProfile()"
            [leftIcon]="faUser"
            [label]="'View Profile'"
          ></app-button>
        </div>
      </div>
      <div class="flex flex-col px-2 py-2 h-full items-end gap-6">
        <fa-icon
          class="
            cursor-pointer
            hover:text-secondary-blue hover:bg-gray-100
            rounded-full
            py-1
            px-2
          "
          [icon]="close"
          (click)="isShowingProfileCard = !isShowingProfileCard"
        ></fa-icon>
        <div class="flex flex-col gap-1">
          <app-button
            [isWhite]="true"
            (click)="onLogout()"
            [leftIcon]="signOutIcon"
            [label]="'Sign Out'"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>