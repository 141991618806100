<div
  class="
    flex
    justify-center
    items-center
    w-full
    h-80
    rounded-2xl
    bg-secondary-white
    shadow-lg
    duration-300
    hover:shadow-xl hover:cursor-pointer
    motion-safe:hover:scale-105 motion-safe:active:scale-100
    active:shadow-lg
  "
  (click)="onClickEvent()"
>
  <div class="w-full h-full">
    <div
      class="
        flex
        w-full
        bg-cover
        rounded-t-2xl
        h-36
        bg-center
        bg-origin-border
        bg-no-repeat
        justify-evenly
        items-end
        p-4
      "
      [ngStyle]="{
        'background-image': thumbnail
      }"
    >
      <!-- <ng-container *ngFor="let keyword of keywords">
        <app-keyword-tag [keyword]="keyword"></app-keyword-tag>
      </ng-container> -->
    </div>
    <div class="py-8 px-4 bg-secondary-white rounded-b-md flex flex-col gap-4">
      <div>
        <h1 class="text-secondary-black text-xl font-bold font-lato">
          {{ serviceName.length > 35 ? (serviceName | slice:0:35) + '...' : serviceName }}
        </h1>
        <p class="text-secondary-black font-lexend">
          {{ serviceDescription }}
        </p>
      </div>
      <div *ngIf="revisionCode" >
        <p class="text-secondary-black font-lexend text-sm">
          {{ revisionInfoText }}: {{ revisionCode }}
        </p>
      </div>

    </div>
  </div>
</div>

