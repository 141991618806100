<label class="{{ isDisabled ? 'text-gray-400' : 'text-primary-white' }} font-medium font-lexend"
  >{{ label }}
  <span *ngIf="required" class="text-primary-magenta">*</span>
</label>
<ng-select
  class="wrapper mt-2 font-lexend"
  placeholder="{{ value ? undefined : placeholder }}"
  [searchable]="searchable"
  [groupBy]="groupeBy"
  [items]="items"
  [readonly]="isDisabled"
  [markFirst]="markFirst"
  [multiple]="multiple"
  [clearable]="clearable"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [(ngModel)]="value"
  (change)="propagateSelected()"
>
</ng-select>
