<div 
    class="border-2 border-primary-white 
        rounded-lg flex flex-col justify-between 
        gap-8 bg-secondary-white text-primary-black my-4"
>
    <div class="flex flex-row justify-between gap-4 w-full p-8">
        <div class="font-lexend text-lg flex flex-row gap-2">
            <fa-icon [icon]="faUser"></fa-icon>
            <span>{{user}}</span>
        </div>
        <div class="font-lexend text-lg flex flex-row gap-2">
            <fa-icon [icon]="faCalendar"></fa-icon>
            <span>{{createdDate}}</span>
        </div>
    </div>
    <div class="flex flex-col w-full px-8 pb-8 justify-start">
        <span class="font-lexend text-2xl">{{commentContent}}</span>
    </div>
</div>
