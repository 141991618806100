
export interface Service {
    id: number;
    name: string;
    updatedAt: string;
    updatedBy: string;
    createdAt: string;
    createdBy: string;
    published: boolean;
    uuid: string;
    publishedRevision: ServiceRevision;
    latestRevision: ServiceRevision;
}

export interface ServiceRevision {
    accessPolicies: ServiceMultiOption[];
    attachments: Attachment[];
    code: number;
    contentSourceManagement: string;
    createdAt: string;
    createdBy: string;
    description: string;
    destineUsageProfile: string;
    documentationURI: string;
    feedbacks: any[];
    id: number;
    interfaces: ServiceMultiOption[];
    plannedEvolutions: string;
    registrationMethods: ServiceMultiOption[];
    resourceConsumptions: ServiceMultiOption[];
    serviceUsageProfile: string;
    shortDescription: string;
    thumbnails: Thumbnail[];
    transactions: Transaction[];
    types: ServiceMultiOption[];
    updatedAt: string;
    updatedBy: string;
    version: string;
}

export interface Transaction {
    id: number;
    name: string;
    description: string;
    type: string;
    reference: string;
    basePriceSingle: number;
    basePriceParametric: number;
}

export interface NewTransaction {
    name: string;
    description: string;
    price: number;
    id?: number;
}

export type Thumbnail = {
    name: string;
    data: any;
    id?: number;
}

export type Attachment = {
    name: string;
    data: any;
    createdAt?: string;
    updatedAt?: string;
    updatedBy?: string;
    createdBy?: string;
    type?: string;
    id?: number;
}

export type NewService = {
    name: string;
    description: string;
    version: string;
    accessPolicy: string;
    resourceConsumption: string;
    type: string;
    interface: string;
    registrationMethod: string;
    destineUsageProfile: string;
    serviceUsageProfile: string;
    contentSourceManagement: string;
    plannedEvolutions: string;
    id?: number;
    uuid?: string;
}

export interface ServiceReview {
    approved: boolean;
    comments: Comment[];
    createdAt: string;
    updatedAt: string;
    id?: number;
}

export interface Comment {
    createdAt: string;
    updatedAt: string;
    content: string;
    createdBy: string;
    id?: number;
}

export interface ServiceMultiOption {
    id: number;
    name: string;
}

export interface ServiceRevisionIndex {
    id: number;
    code: number;
    status: string;
}

export interface ReviewStatus {
    status: string;
}