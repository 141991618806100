import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  CheckboxInputComponent,
  CustomSelectInputComponent,
  DynamicInputComponent,
  InputComponent,
  InputFileComponent,
  SelectComponent,
  SelectInputComponent,
  TextAreaComponent,
  MultipleSelectInputComponent,
  IconButtonComponent,
  HelperTextComponent,
  NavbarComponent,
  FooterComponent,
  SearchBarComponent,
  TabComponent,
  DialogComponent,
  SnackbarComponent,
  ServiceCardComponent
} from './components';
import { RegexInputDirective } from './directives';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListItemComponent } from './components/list-item/list-item.component';
import { CreateItemDialogComponent } from './components/create-item-dialog/create-item-dialog.component';
import { SimpleItemInputComponent } from './components/simple-item-input/simple-item-input.component';
import { CommentCardComponent } from './components/comment-card/comment-card.component';
import { UserFeedbackComponent } from './components/user-feedback/user-feedback.component';


@NgModule({
  declarations: [
    ButtonComponent,
    CheckboxInputComponent,
    CustomSelectInputComponent,
    DynamicInputComponent,
    InputComponent,
    InputFileComponent,
    SelectComponent,
    SelectInputComponent,
    TextAreaComponent,
    MultipleSelectInputComponent,
    IconButtonComponent,
    HelperTextComponent,
    RegexInputDirective,
    NavbarComponent,
    FooterComponent,
    SearchBarComponent,
    TabComponent,
    DialogComponent,
    ListItemComponent,
    CreateItemDialogComponent,
    SnackbarComponent,
    SimpleItemInputComponent,
    ServiceCardComponent,
    CommentCardComponent,
    UserFeedbackComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AngularSvgIconModule,
    RouterModule
  ],
  exports: [
    FontAwesomeModule,
    NavbarComponent,
    FooterComponent,
    ButtonComponent,
    CheckboxInputComponent,
    CustomSelectInputComponent,
    DynamicInputComponent,
    InputComponent,
    InputFileComponent,
    SelectComponent,
    SelectInputComponent,
    TextAreaComponent,
    MultipleSelectInputComponent,
    IconButtonComponent,
    HelperTextComponent,
    RegexInputDirective,
    NavbarComponent,
    FooterComponent,
    SearchBarComponent,
    IconButtonComponent,
    ButtonComponent,
    TabComponent,
    DialogComponent,
    ListItemComponent,
    CreateItemDialogComponent,
    SnackbarComponent,
    SimpleItemInputComponent,
    ServiceCardComponent,
    CommentCardComponent,
    UserFeedbackComponent
  ]
})
export class SharedModule { }
