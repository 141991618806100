import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { UserFeedback } from '@core/models/user-feedback';

@Injectable({
  providedIn: 'root'
})
export class UserFeedbackService{

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseUrlServices = `${environment.api}/services`;
  baseUrlUrlFeedback = `${environment.api}/feedbacks`;

  constructor(private http: HttpClient) {}

  getUserFeedbackByServiceUuid(serviceUuid: string): Observable<UserFeedback[]> {
    const url = this.baseUrlServices+`/${serviceUuid}/feedbacks`;
    return this.http.get<UserFeedback[]>(url);
  }

  getAllUserFeedbackByServiceUuid(serviceUuid: string): Observable<UserFeedback[]> {
    const url = this.baseUrlUrlFeedback+`/service/${serviceUuid}`;
    return this.http.get<UserFeedback[]>(url);
  }

  getUserFeedbackById(userFeedbackId: number): Observable<UserFeedback> {
    const url = this.baseUrlServices+`/${userFeedbackId}`;
    return this.http.get<UserFeedback>(url);
  }

  deleteUserFeedbackById(userFeedbackId: number): Observable<UserFeedback> {
    const url = this.baseUrlUrlFeedback+`/${userFeedbackId}`;
    return this.http.delete<UserFeedback>(url);
  }

  approveUserFeedbackById(userFeedbackId: number, feedbackApproved: boolean): Observable<UserFeedback> {
    const userFeedBack = {
      approve: feedbackApproved
    }
    const url = this.baseUrlUrlFeedback+`/${userFeedbackId}/approve`;
    return this.http.put<UserFeedback>(url, userFeedBack);
  }

  postUserFeedbackByServiceUuid(serviceUuid: string, feedback: UserFeedback): Observable<UserFeedback> {
    const url = this.baseUrlUrlFeedback+`/service/${serviceUuid}`;
    return this.http.post<UserFeedback>(url, feedback);
  }

}
