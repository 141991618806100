import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core';
import { HomeComponent } from 'features/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'services',
    loadChildren: () => import('./features/services/services.module').then(m => m.ServicesModule)
  },
  {
    canActivate: [AuthGuard],
    data: {
      roles: ['desp-user'],
      redirectTo: 'services'
    },
    path: 'my-services',
    loadChildren: () => import('./features/my-services/my-services.module').then(m => m.MyServicesModule)
  },
  ,
  {
    canActivate: [AuthGuard],
    data: {
      roles: ['operator'],
      redirectTo: 'services'
    },
    path: 'service-desk',
    loadChildren: () => import('./features/service-management/service-management.module').then(m => m.ServiceManagementModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
