import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ReviewStatus, Service, ServiceRevision, ServiceRevisionIndex } from '@core';

@Injectable({
  providedIn: 'root'
})
export class ServiceManagementService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseUrl = `${environment.api}/service-desk/services`;

  constructor(private http: HttpClient) { }

  getAllServices(searchString?: string): Observable<Service[]> {
    if (searchString) {
      searchString = '?' + searchString;
    } else {
      searchString = '';
    }
    const url = `${this.baseUrl}${searchString}`;
    return this.http.get<Service[]>(url, this.httpOptions);
  }

  deleteService(serviceUUID: string) {
    const url = `${this.baseUrl}/${serviceUUID}`;
    return this.http.delete(url, this.httpOptions);
  }

  getServiceRevisionIndex(serviceUUID: string): Observable<ServiceRevisionIndex[]> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/index`;
    return this.http.get<ServiceRevisionIndex[]>(url, this.httpOptions);
  }

  getServiceRevisions(serviceUUID: string): Observable<ServiceRevision[]> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions`;
    return this.http.get<ServiceRevision[]>(url, this.httpOptions);
  }

  getServiceRevisionsByID(serviceUUID: string, revisionID: number): Observable<ServiceRevision[]> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions?id=${revisionID}`;
    return this.http.get<ServiceRevision[]>(url, this.httpOptions);
  }

  getServiceRevisionsByCode(serviceUUID: string, revisionCode: number): Observable<ServiceRevision[]> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions?code=${revisionCode}`;
    return this.http.get<ServiceRevision[]>(url, this.httpOptions);
  }

  changeServicePublishState(serviceUUID: string, revisionID: number, state: boolean): Observable<Service> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/${revisionID}/publish`;
    return this.http.put<Service>(url, {'approve': state}, this.httpOptions);
  }

  changeServiceRevisionStatus(serviceUUID: string, revisionID: number, flag: boolean): Observable<ReviewStatus> {
    const url = `${this.baseUrl}/${serviceUUID}/revisions/${revisionID}/reviews/approve`;
    const payload = {
      approve: flag
    }
    return this.http.put<ReviewStatus>(url, payload, this.httpOptions);
  }

}
