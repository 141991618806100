import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PortalLabel } from '@core';

@Injectable({
  providedIn: 'root'
})
export class WebPortalService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseUrl = `${environment.api}/labels`;

  constructor(private http: HttpClient) { }

  getAllLabels(): Observable<PortalLabel[]> {
    const url = this.baseUrl;
    return this.http.get<PortalLabel[]>(url, this.httpOptions);
  }

  getLabelBySlug(slug: string): Observable<PortalLabel> {
    const url = `${this.baseUrl}/${slug}`;
    return this.http.get<PortalLabel>(url, this.httpOptions);
  }
}
