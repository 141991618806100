import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicInputBase } from '../classes/dynamic-input-base';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faDrawPolygon,
  faMapPin,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent implements OnInit {
  @Input() input!: DynamicInputBase<any>;
  @Input() form!: UntypedFormGroup;

  faDrawPolygon: IconDefinition = faDrawPolygon;
  faMapPin: IconDefinition = faMapPin;
  faPencil: IconDefinition = faPencilAlt;

  constructor() {}

  get isValid() {
    return this.form.controls[this.input.key].valid;
  }

  ngOnInit() {}

  onButtonClink(action: string, value?: string) {}
}
