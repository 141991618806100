<div class="block">
  <label class="block text-gray-700 mb-2">{{ label }}
    <span *ngIf="required" class="text-red-500">*</span>
  </label>
  <ng-template #selectComponent>
    <select
      [disabled]="isDisabled"
      class="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      [id]="id"
      [(ngModel)]="value"
      (ngModelChange)="handleInputChange($event)"
      [ngClass]="disabledClass()"
    >
      <option
        disabled
        [ngValue]="null"
        selected
        *ngIf="options?.length === 0; else notEmpty"
      >
        {{ placeholder ?? "No Data" }}
      </option>
      <ng-template #notEmpty>
        <option *ngIf="placeholder" [ngValue]="null" disabled selected>
          {{ placeholder }}
        </option>
      </ng-template>
      <option *ngFor="let option of options" [value]="option.value" class="text-gray-700">
        {{ option.label }}
      </option>
    </select>
  </ng-template>
  <div
    *ngIf="isDisabled && defaultValue; else selectComponent"
    class=" block bg-gray-100 cursor-not-allowed w-full mt-2 rounded-md py-2.5 px-3">
    <span class="text-gray-500">{{defaultValueLabel}}</span>
  </div>
</div>
