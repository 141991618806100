<div class="flex flex-col h-full">
  <label class="text-primary-white font-lexend">{{ label }}  <span *ngIf="required"
                                                  class="text-red-500">*</span></label>
  <textarea
    [ngClass]="textAreaClass"
    class="
    flex-1
      mt-1
      block
      w-full
      rounded-md
      bg-gray-100
      focus:bg-white
      focus:outline-none focus:ring-1
      ring-primary-500
      py-2
      px-3
      font-lexend
    "
    [id]="id"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="handleInputChange($event)"
    [rows]="rows"
  ></textarea>
</div>
