<div [formGroup]="dataForm" class="flex flex-row gap-2">
    <app-input class="{{ simpleInterface ? 'w-8/12' : 'w-8/12 md:w-7/12 lg:w-10/12' }}"
        [formControlName]="formControlFieldName"
        [placeholder]="placeholderText"
        (emitKeyPress)="onKeyPress($event)"
    ></app-input>
    <div *ngIf="!simpleInterface" class="hidden md:flex md:flex-row md:justify-end">
        <app-button
            [label]="'Add'"
            [isTransparent]="true"
            [leftIcon]="faCheck"
            [isDisabled]="!dataForm.valid"
            (buttonClick)="onAdd()"
        ></app-button>
        <app-button
            [label]="'Cancel'"
            [isTransparent]="true"
            [leftIcon]="faTimes"
            (buttonClick)="onCancel()"
        ></app-button>
        <app-button
            [label]="'Dialog'"
            [isTransparent]="true"
            [leftIcon]="faWindowMaximize"
            (buttonClick)="onOpenDialog()"
        ></app-button>
    </div>
    <div class="flex flex-row justify-end {{ simpleInterface ? '' : 'md:hidden' }}">
        <app-icon-button
            [icon]="faCheck"
            (btnClick)="onAdd()"
            [disabled]="!dataForm.valid"
        ></app-icon-button>
        <app-icon-button
            [icon]="faTimes"
            (btnClick)="onCancel()"
        ></app-icon-button>
        <app-icon-button
            [icon]="faWindowMaximize"
            (btnClick)="onOpenDialog()"
        ></app-icon-button>
    </div>
</div>
